const theme = {
  global: {
    font: {
      family: 'avenir',
      size: '18px',
      height: '18px'
    },
    colors: {
      brand: '#288094',
      text: '#373536',
      textAlt: '#868686',
      link: '#288094',
      content: '#fff',
      border: '#cacaca',
      alert: '#ff4040'
    },
    input: {
      padding: {
        vertical: '11px',
        horizontal: '15px',
      },
    },
    focus: {
      border: {
        color: "none",
      }
    }
  },
  heading: {
    level: {
      "1": {
        "font": {
          family: 'avenir',
        },
        "small": {
          "size": "34px",
          "height": "38px",
          "maxWidth": "816px"
        },
        "medium": {
          "size": "50px",
          "height": "54px",
          "maxWidth": "1200px"
        },
        "large": {
          "size": "82px",
          "height": "86px",
          "maxWidth": "1968px"
        },
        "xlarge": {
          "size": "114px",
          "height": "118px",
          "maxWidth": "2736px"
        }
      },
      "2": {
        "font": {
          family: 'avenir',
        },
        "small": {
          "size": "26px",
          "height": "30px",
          "maxWidth": "624px"
        },
        "medium": {
          "size": "34px",
          "height": "38px",
          "maxWidth": "816px"
        },
        "large": {
          "size": "50px",
          "height": "54px",
          "maxWidth": "1200px"
        },
        "xlarge": {
          "size": "66px",
          "height": "70px",
          "maxWidth": "1584px"
        }
      },
      "3": {
        "font": {
          family: 'avenir',
        },
        "small": {
          "size": "22px",
          "height": "26px",
          "maxWidth": "528px"
        },
        "medium": {
          "size": "26px",
          "height": "30px",
          "maxWidth": "624px"
        },
        "large": {
          "size": "34px",
          "height": "38px",
          "maxWidth": "816px"
        },
        "xlarge": {
          "size": "42px",
          "height": "46px",
          "maxWidth": "1008px"
        }
      },
      "4": {
        "font": {
          family: 'avenir',
        },
        "small": {
          "size": "20px",
          "height": "24px",
          "maxWidth": "432px"
        },
        "medium": {
          "size": "20px",
          "height": "24px",
          "maxWidth": "432px"
        },
        "large": {
          "size": "20px",
          "height": "24px",
          "maxWidth": "432px"
        },
        "xlarge": {
          "size": "20px",
          "height": "24px",
          "maxWidth": "432px"
        }
      },
      "5": {
        "font": {
          family: 'avenir',
        },
        "small": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "384px"
        },
        "medium": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "384px"
        },
        "large": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "384px"
        },
        "xlarge": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "384px"
        }
      },
      "6": {
        "font": {
          family: 'avenir',
        },
        "small": {
          "size": "14px",
          "height": "18px",
          "maxWidth": "336px"
        },
        "medium": {
          "size": "14px",
          "height": "18px",
          "maxWidth": "336px"
        },
        "large": {
          "size": "14px",
          "height": "18px",
          "maxWidth": "336px"
        },
        "xlarge": {
          "size": "14px",
          "height": "18px",
          "maxWidth": "336px"
        }
      }
    },
  },
  text: {
    "xsmall": {
      "size": "12px",
      "height": "16px",
      "maxWidth": "288px"
    },
    "small": {
      "size": "14px",
      "height": "18px",
      "maxWidth": "336px"
    },
    "medium": {
      "size": "17px",
      "height": "21px",
      "maxWidth": "432px"
    },
    "large": {
      "size": "22px",
      "height": "26px",
      "maxWidth": "528px"
    },
    "xlarge": {
      "size": "26px",
      "height": "30px",
      "maxWidth": "624px"
    },
    "xxlarge": {
      "size": "30px",
      "height": "36px",
      "maxWidth": "816px"
    },
  },
  paragraph: {
    "xsmall": {
      "size": "10px",
      "height": "12px",
      "maxWidth": "288px"
    },
    "small": {
      "size": "12px",
      "height": "14px",
      "maxWidth": "336px"
    },
    "medium": {
      "size": "17px",
      "height": "21px",
      "maxWidth": "432px"
    },
    "large": {
      "size": "22px",
      "height": "26px",
      "maxWidth": "528px"
    },
    "xlarge": {
      "size": "26px",
      "height": "30px",
      "maxWidth": "624px"
    },
    "xxlarge": {
      "size": "30px",
      "height": "36px",
      "maxWidth": "816px"
    },
  },
  anchor: {
    color: 'link',
    hover: {
      textDecoration: 'none',
    },
  },
  button: {
    extend: {
      fontSize: '15px',
      fontWeight: 'bold',
      lineHeight: '1',
    },
    primary: {
      extend: {
        width: 'auto',
        color: '#fff',
        background: '#007C90',
        letterSpacing: '0.5px'
      },
    },
    color: '#007C90',
    border: {
      width: '3px',
      color: '#007C90',
      radius: '7px',
    },
    padding: {
      vertical: '13px',
      horizontal: '35px',
    },
  },
  formField: {
    border: {
      color: 'transparent',
    },
    margin: {
      bottom: '20px',
    },
  },
  textInput: {
    extend: {
      lineHeight: '22px',
      background: '#fff',
    },
  },
  select: {
    icons: {
      color: '#000',
    },
    background: '#fff',
  },
  radioButton: {
      size: '20px',
  },
  accordion: {
    icons: {
      color: '#000',
    },
    border: {
      style: 'hidden',
      size: '0px',
    },
    panel: {
      border: {
        style: 'hidden',
        size: '0px',
      },
    },
  },
  pagination: {
    controls: {
      gap: 'small',
    },
  },
};

export default theme;



